<template>
  <div class="contrainer-right" v-loading="loading">
    <custom-actions :title="(deviceType === '0' ? '基站' : '基站') + '设备详情'">
    <div slot="header" v-if="detailInfo">
          <el-button v-if="permission.edit" type="text" @click="onEdit(detailInfo.deviceNum)">编辑</el-button>
          <el-button v-if="permission.del" class="del-btn" type="text" @click="onDelete(detailInfo.deviceNum)">删除</el-button>
          <el-button type="text" @click="goHistory(detailInfo.deviceNum)">历史数据</el-button>
        </div>
      <div class="left">
        <!-- <div class="title-box title-box2">设备开关
          <el-switch
            v-model="detailInfo.deviceNew.showSwitch"
            :disabled="detailInfo.deviceNew.deviceDel == '2'"
            @change="changeSwitch(detailInfo.deviceNum, detailInfo.deviceNew.deviceState)">
          </el-switch>
        </div> -->

        <div class="title-box title-box2">基本信息</div>
        <table class="content-table" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td>设备串号：</td>
            <td>{{detailInfo.deviceNum}}</td>
          </tr>
          <tr>
            <td>设备开关：</td>
            <td>
              <el-switch
                v-model="detailInfo.deviceNew.showSwitch"
                :disabled="detailInfo.deviceNew.deviceDel == '2'"
                @change="changeSwitch(detailInfo.deviceNum, detailInfo.deviceNew.deviceState)">
              </el-switch>
            </td>
          </tr>
          <tr>
            <td>设备状态：</td>
            <td>
              <el-tag type="success" v-if="detailInfo.deviceNew.deviceDel == '1'">在线</el-tag>
              <el-tag type="danger" v-else-if="detailInfo.deviceNew.deviceDel == '2'">离线</el-tag>
              <span v-else>--</span>
            </td>
          </tr>
          <tr>
            <td>设备实时电流：</td>
            <td class="font-big">{{ detailInfo.deviceNew.deviceAmpere || "--" }}</td>
          </tr>
          <tr>
            <td>漏电电流：</td>
            <td class="font-big">{{ detailInfo.deviceNew.ldData || "--" }}</td>
          </tr>
          <tr>
            <td>电表读数：</td>
            <td class="font-big">{{ detailInfo.deviceNew.electricityValue || "--" }}</td>
          </tr>
           <tr>
            <td>自动分闸时间：</td>
            <td class="font-big">{{ detailInfo.autoClosingPower1 || "--" }}</td>
          </tr>
           <tr>
            <td>自动合闸时间：</td>
            <td class="font-big">{{ detailInfo.autoOpeningPower1 || "--" }}</td>
          </tr>
        </table>

        <div class="title-box title-box2">保护设置</div>
        <table class="content-table big" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td>瞬时保护：</td>
            <td>
              <div :class=" detailInfo.instantaneous1 == '0' && detailInfo.instantaneous2 == '0' ? 'close-style' : 'open-style'"></div>
            </td>
            <td>短延时保护：</td>
            <td>
              <div :class=" detailInfo.shortDelay1 == '0' && detailInfo.shortDelay2 == '0' ? 'close-style' : 'open-style'"></div>
            </td>
          </tr>
          <tr>
            <td>过载保护：</td>
            <td>
              <div
                :class="
                  detailInfo.overload1 == '0' &&
                  detailInfo.overload2 == '0' &&
                  detailInfo.overload3 == '0'
                    ? 'close-style'
                    : 'open-style'
                "
              ></div>
            </td>
            <td>漏电保护：</td>
            <td>
              <div :class=" detailInfo.leakageAction1 == '0' && detailInfo.leakageAction2 == '0' ? 'close-style' : 'open-style'"></div>
            </td>
          </tr>
          <tr>
            <td>过电压保护：</td>
            <td>
              <div :class=" detailInfo.overvoltage1 == '0' && detailInfo.overvoltage2 == '0' ? 'close-style' : 'open-style'"></div>
            </td>
            <td>欠电压保护：</td>
            <td>
              <div :class=" detailInfo.underVoltage1 == '0' && detailInfo.underVoltage2 == '0' ? 'close-style' : 'open-style'"></div>
            </td>
          </tr>
          <tr>
            <td>超温保护：</td>
            <td>
              <div :class="detailInfo.overTemperature1 == '0' && detailInfo.overTemperature2 == '0' ? 'close-style' : 'open-style'"></div>
            </td>
            <td>拉弧保护：</td>
            <td>
              <div :class="detailInfo.arc1 == '0' && detailInfo.arc2 == '0' && detailInfo.arc3 == '0' ? 'close-style' : 'open-style'"></div>
            </td>
          </tr>
          <tr>
            <td>缺相保护：</td>
            <td>
              <div :class=" detailInfo.openPhase1 == '0' && detailInfo.openPhase2 == '0' ? 'close-style' : 'open-style'"></div>
            </td>
             <td>自动开合闸：</td>
            <td>
              <div :class=" detailInfo.autoPower ? 'open-style' : 'close-style'"></div>
            </td>
            <!-- <td>内空开电压保护：</td>
            <td>
              <div :class=" detailInfo.v461 == '0' && detailInfo.v462 == '0' ? 'close-style' : 'open-style'"></div>
            </td> -->
            <!-- <td>防偷电电流差值：</td>
            <td>
              <div :class=" detailInfo.antiStealing == '0' ? 'close-style' : 'open-style'"></div>
            </td> -->
          </tr>
        </table>

        <el-row>
          <el-col :span="8">
            <div class="title-box title-box2">A相信息</div>
            <table class="content-table" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td>A相电流：</td>
                <td class="font-big">{{ detailInfo.deviceNew.laData || "--" }}</td>
              </tr>
              <tr>
                <td>A相电压：</td>
                <td class="font-big">{{ detailInfo.deviceNew.vaData || "--" }}</td>
              </tr>
              <tr>
                <td>A相温度：</td>
                <td class="font-big">{{ detailInfo.deviceNew.waData || "--" }}</td>
              </tr>
            </table>
          </el-col>
          <el-col :span="8">
            <div class="title-box title-box2">B相信息</div>
            <table class="content-table" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td>B相电流：</td>
                <td class="font-big">{{ detailInfo.deviceNew.lbData || "--" }}</td>
              </tr>
              <tr>
                <td>B相电压：</td>
                <td class="font-big">{{ detailInfo.deviceNew.vbData || "--" }}</td>
              </tr>
              <tr>
                <td>B相温度：</td>
                <td class="font-big">{{ detailInfo.deviceNew.wbData || "--" }}</td>
              </tr>
            </table>
          </el-col>
          <el-col :span="8">
            <div class="title-box title-box2">C相信息</div>
            <table class="content-table" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td>C相电流：</td>
                <td class="font-big">{{ detailInfo.deviceNew.lcData || "--" }}</td>
              </tr>
              <tr>
                <td>C相电压：</td>
                <td class="font-big">{{ detailInfo.deviceNew.vcData || "--" }}</td>
              </tr>
              <tr>
                <td>C相温度：</td>
                <td class="font-big">{{ detailInfo.deviceNew.wcData || "--" }}</td>
              </tr>
            </table>
          </el-col>
        </el-row>

      </div>
    </custom-actions>
  </div>
</template>

<script>
export default {
  name: 'DeviceChildDetail',
  data () {
    return {
      loading: false,
      detailInfo: {
        deviceNew: {}
      }
    }
  },
  computed: {
    deviceNum () {
      return this.$route.params.deptId
    },
    deviceType () {
      return this.$route.params.deviceType
    },
    permission () {
      return this.$store.state.permission
    }
  },
  watch: {
    $route: {
      handler: function (newV) {
        this.loadData()
      },
      immediate: true
    }
  },
  created () {
  },
  methods: {
    parseTimestr (str) {
      const result = [str.slice(0, 2), str.slice(2)]
      return result[0] + ':' + result[1]
    },
    loadData () {
      this.loading = true
      this.$axios.get(this.$apis.smartDevice.selectDeviceNum, {
        params: { deviceNum: this.deviceNum }
      }).then(res => {
        res.deviceNew.showSwitch = res.deviceNew.deviceState === '1'
        res.arc = res.arc || '0_0_0'
        // res.v46 = res.v46 || '0_0'
        res.arc1 = res.arc.split('_')[0]
        res.arc2 = res.arc.split('_')[1]
        res.arc3 = res.arc.split('_')[2]
        res.openPhase1 = res.openPhase.split('_')[0] || ''
        res.openPhase2 = res.openPhase.split('_')[1] || '0'
        res.instantaneous1 = res.instantaneous.split('_')[0] || ''
        res.instantaneous2 = res.instantaneous.split('_')[1] || '0'
        res.shortDelay1 = res.shortDelay.split('_')[0] || ''
        res.shortDelay2 = res.shortDelay.split('_')[1] || '0'
        res.leakageAction1 = res.leakageAction.split('_')[0] || ''
        res.leakageAction2 = res.leakageAction.split('_')[1] || '0'
        res.overvoltage1 = res.overvoltage.split('_')[0]
        res.overvoltage2 = res.overvoltage.split('_')[1]
        res.underVoltage1 = res.underVoltage.split('_')[0]
        res.underVoltage2 = res.underVoltage.split('_')[1]
        res.overTemperature1 = res.overTemperature.split('_')[0]
        res.overTemperature2 = res.overTemperature.split('_')[1]
        const overload = res.overload.split('_')
        res.overload1 = overload[0] || '0'
        res.overload2 = overload[1] || '0'
        res.overload3 = overload[2] || '0'
        // res.v461 = res.arc.split('_')[0]
        // res.v462 = res.arc.split('_')[1]
        res.autoPower = res.autoOpeningPower.split('_')[1] === '1'
        if (res.autoPower) {
          res.autoOpeningPower1 = this.parseTimestr(res.autoOpeningPower.split('_')[0])
          res.autoClosingPower1 = this.parseTimestr(res.autoClosingPower.split('_')[0])
        }
        this.detailInfo = res
      }).finally(() => {
        this.loading = false
      })
    },
    changeSwitch (deviceNum, type) {
      this.changeDeviceState(deviceNum, type)
    },
    changeDeviceState (deviceNum, type) {
      const text = type === '0' ? '合闸' : type === '1' ? '分闸' : ''
      this.$confirm('确定' + text + ', 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        if (type === '0') { // 合闸
          this.$axios.post(this.$apis.smartDevice.openDevice, { deviceNum }).then(res => {
            this.page = 1
            setTimeout(() => {
              this.loading = false
              this.$message.success('设备合闸成功')
              this.loadData()
            }, 5000)
          })
        } else if (type === '1') { // 分闸
          this.$axios.post(this.$apis.smartDevice.closeDevice, { deviceNum }).then(res => {
            this.page = 1
            setTimeout(() => {
              this.loading = false
              this.$message.success('设备分闸成功')
              this.loadData()
            }, 5000)
          })
        }
      }).catch(() => {
        this.detailInfo.deviceNew.showSwitch = !this.detailInfo.deviceNew.showSwitch
      })
    },
    goHistory (deviceNum) {
      this.$router.push('/smartDevice/history?deviceNum=' + deviceNum)
    },
    onEdit (id) {
      this.$router.push({
        name: 'smartDeviceEdit',
        params: {
          id,
          showList: 1
        }
      })
    },
    onDelete (deviceNum) {
      this.$confirm('确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .post(this.$apis.smartDevice.deleteDevice, { deviceNum })
            .then(res => {
              this.$message.success('删除成功')
              this.$router.push('/smartDevice/list')
            })
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.left {
  margin-top: -15px;
}

.font-big {
  font-size: 16px !important;
  font-weight: 600;
}

.title-box {
  font-size: 14px;
  margin: 10px 0 30px 0;
  padding-left: 10px;
  position: relative;
  &.title-box2 {
    margin-top: 40px;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    background: #007165;
  }
}
.content-table {
  width: 400px;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-collapse: collapse;

  &.big {
    width: 800px;

    tr {
      td {
        width: 25%;
      }
    }
  }

  tr {
    td {
      font-size: 14px;
      line-height: 38px;
      display: inline-block;
      width: 50%;
      height: 38px;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      text-align: left;
      padding: 0 10px;
      &:nth-child(2n-1) {
        text-align: right;
      }

      .td-value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.close-style {
  width: 36px;
  height: 8px;
  display: inline-block;
  overflow: visible;
  background-color: #f40909;
  border-radius: 16px;
  box-shadow: 0px 0px 6px rgba(255, 0, 0, 0.8);
}
.open-style {
  width: 36px;
  height: 8px;
  display: inline-block;
  overflow: visible;
  background-color: green;
  border-radius: 16px;
  box-shadow: 0px 0px 6px rgba(0, 255, 0, 0.8);
}
</style>
